import { slide as Menu } from 'react-burger-menu'

const BurgerMenu = ()=>{

    var styles = {
        bmBurgerButton: {
          position: 'fixed',
          width: '36px',
          height: '30px',
          left: '20px',
          top: '25px'
        },
        bmBurgerBars: {
          background: '#373a47'
        },
        bmBurgerBarsHover: {
          background: '#a90000'
        },
        bmCrossButton: {
          height: '30px',
          width: '30px'
        },
        bmCross: {
        
          background: 'black'
        },
        bmMenuWrap: {
          position: 'fixed',
          height: '100%'
        },
        bmMenu: {
          background: '#FDF1E6',
          padding: '2.5em 1.5em 0',
          fontSize: '26px',
         
        },
        bmMorphShape: {
          fill: '#373a47'
        },
        bmItemList: {
         
          display:"flex",
          flexDirection: "column"

        },
        bmItem: {
            display:"flex",
          
          justifyContent: "center",
          alignItems: "center",
          marginBottom:10

        },
        bmOverlay: {
          background: 'rgba(0, 0, 0, 0.3)'
        }
      }
    return(
        <Menu
         
            styles={styles}
            width={ '100%' }>
            <a id="home" className="menu-item" href="/">
                <div class="w-full h-14 bg-white rounded-md relative mt-5 max-w-lg" >
                    <div
                        className="hover:bg-blue-700 w-full h-14 bg-white rounded-md relative z-10 border-2 font-bold flex items-center justify-center"
                        >
                       主頁
                    </div>     
                    <div className=" bg-black z-0 absolute w-full h-14 top-1 left-1 rounded-md"/>
                </div>
            </a>
            <a id="about" className="menu-item" href="/aboutus">
                <div class="w-full h-14 bg-white rounded-md relative mt-5 max-w-lg" >
                    <div
                        className="hover:bg-blue-700 w-full h-14 bg-white rounded-md relative z-10 border-2 font-bold flex items-center justify-center"
                       
                        >
                       關於pawme
                    </div>     
                    <div className=" bg-black z-0 absolute w-full h-14 top-1 left-1 rounded-md"/>
                </div>
            </a>
            <a id="contact" className="menu-item" href="mailto:hello@pawme.io">
                <div class="w-full h-14 bg-white rounded-md relative mt-5 max-w-lg" >
                    <div
                        className="hover:bg-blue-700 w-full h-14 bg-white rounded-md relative z-10 border-2 font-bold flex items-center justify-center"
                       
                        >
                       聯絡我們
                    </div>     
                    <div className=" bg-black z-0 absolute w-full h-14 top-1 left-1 rounded-md"/>
                </div>
            </a>
            
        </Menu>
    )

}
export default BurgerMenu