import Footer from "../components/footer"
import BurgerMenu from "../components/burgerMenu"


const AboutUs = ()=>{
    const TopBar = ()=>{
        return(
            <div className="lg:hidden">
                <BurgerMenu></BurgerMenu>
           
        </div>
        )
    }

    return(
        <div>
            <TopBar></TopBar>
                 
            <div class = "h-20 w-screen bg-orange flex justify-center items-center">
                <img 
                class = "h-10 lg:h-14"
                src = "/images/logo.png"></img>
                


            </div>
            <div class = "relative overflow-hidden h-screen w-screen flex z-0 ">
                <div class="absolute z-0 flex items-start h-full w-full">
                    <img 
                    className = "sticky top-0 w-full h-full object-cover hidden lg:block"
                    src = "/images/aboutusBg.jpg"></img>

                    <img 
                    className = "sticky top-0 w-full h-full object-cover block lg:hidden"
                    src = "/images/aboutusBg_mobile.jpg"></img>

                </div>

                <div
                className = "grid lg:grid-cols-2 pt-32 z-10">
                    <div className="flex flex-col items-center lg:items-start lg:mx-30 w-screen lg:w-full">
                        <div className="xl:text-8xl lg:text-6xl text-5xl font-bold tracking-widest relative flex text-white">關於我們</div>
                        <div className="xl:text-3xl lg:text-2xl text-2xl font-bold tracking-widest relative flex text-white mt-7 mx-10 lg:mx-0">我地嘅目標好簡單，就係記錄全港各區嘅貓貓，為貓奴創造一張貓貓地圖。</div>


                    
                    
                </div>


            </div>
            </div>

            <div className="w-screen bg-lightBg items-center flex flex-col">
               <div className="flex flex-row items-center space-x-2 mt-10">
               <img 
                class = "h-10 lg:h-14"
                src = "/images/catIcon.png" />
                <div className="font-bold text-2xl">Pawme團隊</div>

               </div>

               <div className="grid grid-row-2 md:grid-cols-2 w-screen max-w-screen-lg">
                    <div className="w-full pt-10 px-10 pb-0 md:pb-10">
                        <div className=" bg-white w-full h-full rounded-md border border-gray-superLight">
                            <div className="p-7 flex flex-col items-center">
                            <img src = "/images/ivan.jpg"/>
                            <div className=" font-medium text-xl mt-5">IVAN</div>
                            <div className=" font-normal text-md">CO-FOUNDER</div>
                            <div className=" font-normal text-sm mt-5">Product Manager @ AI startup</div>
                            <div className=" font-normal text-sm mt-1 text-gray-light">Software Dev | System Design | Tech</div>
                            <div className=" font-normal text-sm text-gray-light">love building cool stuff</div>
                   


                            </div>
                            
                        </div>

                    </div>

                    <div className="w-full p-10">
                        <div className=" bg-white w-full h-full rounded-md border border-gray-superLight">
                            <div className="p-7 flex flex-col items-center">
                            <img src = "/images/pakwai.jpg"/>
                            <div className=" font-medium text-xl mt-5">PAK WAI</div>
                            <div className=" font-normal text-md">CO-FOUNDER</div>
                            <div className=" font-normal text-sm mt-5">Product Design Lead @ Fintech</div>
                            <div className=" font-normal text-sm mt-1 text-gray-light">User Experience | Art Direction | Creative</div>
                            <div className=" font-normal text-sm text-gray-light">love making pretty stuff</div>
                   


                            </div>
                            
                        </div>

                    </div>

                </div>

            </div>
            <Footer/>

           
        </div>
    )
}
export default AboutUs