const Footer = ()=>{
    const appleUrl = "https://apps.apple.com/hk/app/pawme-%E5%B0%8B%E6%89%BE%E8%B2%93%E5%BA%97%E9%95%B7/id6468931709"
    const googleUrl = "https://play.google.com/store/apps/details?id=com.pawme_app"

    return(
        
        <div className=" h-72 w-full flex flex-col">
        <div className=" absolute h-72">
            <img 
            src ="/images/bottomBanner.jpeg"
            className="w-screen h-full object-cover"/>

        </div>
        <div className="w-full items-center justify-center flex flex-col">

            <img 
            class = "h-10 relative mt-10"
            src = "/images/logo.png"></img>
       
            <div className="flex flex-row mt-10 space-x-5">
                    <a href={appleUrl}>
                <button 
                className = " relative w-40 lg:w-60 h-auto bg-black text-white rounded-xl font-bold ">
                    <img 
                    className="w-full h-full"
                    src="/images/appstore.png"/>
                </button>
                </a>
                <a href={googleUrl}>
                <button className = " relative w-40 lg:w-60 h-auto bg-black text-white rounded-xl font-bold ">
                    <img src="/images/googleplay.png"/>
                </button>
                </a>
            </div>

            <div className="flex flex-row space-x-5 relative mt-5">
                <div className="flex flex-row space-x-2 items-center font-medium text-white">
                    <img 
                    className="h-5 w-5"
                    src="/images/mail.png"/>
                    <div>hello@pawme.io</div>
                   
                </div>
                <div className="flex flex-row space-x-2 items-center font-medium text-white">
                    <img 
                    className="h-5 w-5"
                    src="/images/ig.png"/>
                    <div>@pawmehk</div>
                   
                </div>

            </div>

        </div>
        </div>
    )
}

export default Footer