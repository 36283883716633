import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyDKIJrkImoGqC1CB7tm4mwWKXuHXdhnp40",
    authDomain: "pawme-e532e.firebaseapp.com",
    databaseURL: "https://pawme-e532e-default-rtdb.firebaseio.com",
    projectId: "pawme-e532e",
    storageBucket: "pawme-e532e.appspot.com",
    messagingSenderId: "1098388776905",
    appId: "1:1098388776905:web:502faca4ea7a4ed84b2a18",
    measurementId: "G-QKJMXQX0LR"
  };
  
  // Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);