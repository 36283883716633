import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Test from './pages/Test';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="test" element={<Test />} />

         
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
