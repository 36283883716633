import { db } from "../components/setupFirebase"
import { getDatabase, ref, set, push } from "firebase/database";
import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu'
import BurgerMenu from "../components/burgerMenu";

const Home = ()=>{
   const appleUrl = "https://apps.apple.com/hk/app/pawme-%E5%B0%8B%E6%89%BE%E8%B2%93%E5%BA%97%E9%95%B7/id6468931709"
    const googleUrl = "https://play.google.com/store/apps/details?id=com.pawme_app"

    const [contactName, setContactName] = useState("")
    const [contact, setContact] = useState("")
    const [openMenu, setOpenMenu] = useState(false)

    const handleClick = ()=>{
        if(contactName != "" && contact != ""){
            const protectRef = ref(db, 'protectPlanContact');
            const newRef = push(protectRef);
            set(newRef, {
                contact:contact,
                contactName:contactName
            });

            window.alert('多謝你登記, 我哋會盡快聯絡你');

        }else{
            window.alert('請輸入完整資料');
        }
    }
    

    const TopBar = ()=>{
        return(
            <div className="lg:hidden">
                <BurgerMenu></BurgerMenu>
           
        </div>
        )
    }
    return(
        <div>
            <TopBar></TopBar>
            <div class = "h-20 w-screen bg-orange flex justify-center items-center">
                <img 
                class = "h-10 lg:h-14"
                src = "/images/logo.png"></img>
                


            </div>
            <div class = "relative overflow-hidden h-screen w-screen flex z-0 ">
                <div class="absolute z-0 flex items-start h-full w-full">
                <img 
                className = "sticky top-0 w-full h-full object-cover hidden md:block"
                src = "/images/topBg.jpg"></img>

                <img 
                className = "sticky top-0 w-full h-full object-cover block md:hidden"
                src = "/images/topBg_mobile.jpg"></img>

                </div>

                <div
                className = "grid lg:grid-cols-2 pt-32">
                <div className="flex flex-col items-center lg:items-start lg:mx-30 w-screen lg:w-full">
                    <div className="xl:text-8xl lg:text-6xl text-5xl font-bold tracking-widest relative flex text-white">香港貓貓地圖</div>
                    <div className="xl:text-3xl lg:text-2xl text-2xl font-bold tracking-widest relative flex text-white mt-7">記錄所有可愛嘅</div>
                    <div className="xl:text-3xl lg:text-2xl text-2xl font-bold tracking-widest relative flex text-white mt-2">貓店長，貓侍應，貓員工！</div>

                    <div className="flex flex-col">
                        <a href={appleUrl}>
                    <button 
                    className = " relative w-60 h-14 bg-black text-white rounded-xl font-bold mt-7">
                        <img src="/images/appstore.png"/>
                    </button>
                    </a>
                    <a href={googleUrl}>
                    <button className = " relative w-60 h-14 bg-black text-white rounded-xl font-bold mt-3">
                        <img src="/images/googleplay.png"/>
                    </button>
                    </a>
                    </div>
                
               </div>


            </div>

            </div>
            
            <div className="w-screen bg-lightBg items-center flex flex-col">
                <div className="xl:text-5xl lg:text-3xl text-2xl font-bold tracking-widest relative flex text-black mt-30"> 什麼是貓店長地圖？</div>

                <div className="flex flex-col lg:flex-row lg:space-x-30 mt-20 mb-30 mx-20 space-y-20 lg:space-y-0">
                    <div className="flex flex-col items-center">
                        <img src ="/images/phone1.jpg"/>
                        <div className="xl:text-xl lg:text-xl sm:text-xl font-bold tracking-widest relative flex text-black mt-5 text-wrap break-words w-60">朝聖香港貓貓, 總有一隻貓貓在附近</div>

                    </div>

                    <div className="flex flex-col items-center">
                        <img src ="/images/phone2.jpg"/>
                        <div className="xl:text-xl lg:text-xl sm:text-xl font-bold tracking-widest relative flex text-black mt-5 text-wrap break-words w-60">野生捕獲貓店長,分享貓貓資訊。</div>

                    </div>

                    <div className="flex flex-col items-center">
                        <img src ="/images/phone3.jpg"/>
                        <div className="xl:text-xl lg:text-xl sm:text-xl font-bold tracking-widest relative flex text-black mt-5 text-wrap break-words w-60">查閱尋貓記錄！隨時搵返你最鍾意嘅貓貓。</div>

                    </div>

                </div>

           

            </div>
            
            <div className="w-screen h-60 bg-lightBg items-center flex flex-col relative flex justify-center items-end">
                <img 
                className="object-cover absolute w-full h-full z-0"
                src="/images/banner.jpg" />

                <div 
                className="z-1 absolute lg:right-20 flex flex-row">
                <img
                src="/images/googleApple.png"
                />

                </div>
                


            </div>

            <div className="w-screen bg-lightBg items-center flex flex-col px-10">
                <div class="flex flex-col items-center max-w-4xl">
                    <div className="xl:text-5xl lg:text-3xl text-2xl font-bold tracking-widest relative flex text-black mt-30"> 保護貓店長計劃進行中！</div>
                    <div className="xl:text-xl lg:text-xl sm:text-xl font-bold tracking-widest relative flex text-black mt-8"> PAWME希望大家開心搵貓貓之外， 一齊為貓店長們創造一個安全網💪，防止偷貓/走失事件發生。</div>

                    <div className="flex flex-col lg:flex-row lg:space-x-30 mt-20 mb-10 lg:mb-20">
                            <div className="flex-1 flex-col items-center mb-10 sm:mb-0">
                                <div className="">
                                <img src ="/images/protect1.jpg"/>
                                <div className="xl:text-lg lg:text-lg sm:text-lg font-medium tracking-widest relative flex text-black mt-5 text-wrap break-words w-full">Pawme會提供一個定位名牌比店主，再由我地嘅軟件監視貓貓位置，店主唔需要做任何管理/學用APP。</div>


                                </div>
                            
                            </div>

                            <div className="flex-1 flex-col items-center mb-10 sm:mb-0">
                            <div className="">
                                <img src ="/images/protect2.jpg"/>
                                <div className="xl:text-lg lg:text-lg sm:text-lg font-medium tracking-widest relative flex text-black mt-5 text-wrap break-words w-full">店主可以隨時同PAWME聯絡並得知貓貓大約位置，如有走失/懷疑被偷事件，APP 會發出通知 ，喺附近嘅貓奴可以幫手留意！</div>
                            </div>
                            </div>
                    </div>
                </div>
            </div>

            <div className="w-screen bg-lightOrange items-center flex flex-col px-10 relative">
                <div className=" flex flex-col items-center max-w-4xl relative w-auto mb-20">
                    <div className="xl:text-5xl lg:text-3xl text-2xl font-bold tracking-widest relative flex text-black mt-20"> 貓店長需要你嘅幫手💪💪💪</div>
                    <div className="xl:text-xl lg:text-xl sm:text-xl font-bold tracking-widest relative flex text-black mt-8"> 如果你發現左貓店長，又同店主相熟的話，可以介紹吓呢個計劃，幫佢地嘅貓店長加入全球定位(5元硬幣大小)，預防偷貓/走失 如果你係店主，可以留低聯絡方法！</div>

                    <div class="w-full h-14 bg-white rounded-md relative mt-10 max-w-lg" >
                        <div class="w-full h-14 bg-white rounded-md relative z-10" >
                            <input
                                value={contactName}
                                onChange={(event) => setContactName(event.target.value)}
                                type="text"
                                className="border-2  border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full h-full"
                                placeholder="聯絡人"
                                />
                        
                        </div> 
                            <div className=" bg-black z-0 absolute w-full h-14 top-1 left-1 rounded-md">

                            </div>

                    </div>

                    <div class="w-full h-14 bg-white rounded-md relative mt-5  max-w-lg" >
                        <div class="w-full h-14 bg-white rounded-md relative z-10" >
                            <input
                                value={contact}
                                onChange={(event) => setContact(event.target.value)}
                                type="text"
                                className="border-2  border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full h-full"
                                placeholder="電話 / email"
                                />
                        
                        </div> 
                            <div className=" bg-black z-0 absolute w-full h-14 top-1 left-1 rounded-md">

                            </div>

                    </div>

                    <div class="w-full h-14 bg-white rounded-md relative mt-5 max-w-lg" >
                    <button
                        className="hover:bg-blue-700 w-full h-14 bg-midOrange rounded-md relative z-10 border-2 font-bold flex items-center justify-center"
                        onClick={handleClick}
                        >
                       送出
                    </button>
                       
                            <div className=" bg-black z-0 absolute w-full h-14 top-1 left-1 rounded-md">

                            </div>

                    </div>


                </div>
               
            </div>

            <div className=" h-72 w-full flex flex-col">
                <div className=" absolute h-72">
                    <img 
                    src ="/images/bottomBanner.jpeg"
                    className="w-screen h-full object-cover"/>

                </div>
                <div className="w-full items-center justify-center flex flex-col">

                    <img 
                    class = "h-10 relative mt-10"
                    src = "/images/logo.png"></img>
               
                    <div className="flex flex-row mt-10 space-x-5">
                            <a href={appleUrl}>
                        <button 
                        className = " relative w-40 lg:w-60 h-auto bg-black text-white rounded-xl font-bold ">
                            <img 
                            className="w-full h-full"
                            src="/images/appstore.png"/>
                        </button>
                        </a>
                        <a href={googleUrl}>
                        <button className = " relative w-40 lg:w-60 h-auto bg-black text-white rounded-xl font-bold ">
                            <img src="/images/googleplay.png"/>
                        </button>
                        </a>
                    </div>

                    <div className="flex flex-row space-x-5 relative mt-5">
                        <div className="flex flex-row space-x-2 items-center font-medium text-white">
                            <img 
                            className="h-5 w-5"
                            src="/images/mail.png"/>
                            <div>hello@pawme.io</div>
                           
                        </div>
                        <div className="flex flex-row space-x-2 items-center font-medium text-white">
                            <img 
                            className="h-5 w-5"
                            src="/images/ig.png"/>
                            <div>@pawmehk</div>
                           
                        </div>

                    </div>

                </div>

            </div>
            
           

            
        </div>
    )
}
export default Home