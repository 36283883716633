import React, { useRef, useState } from 'react';
import axios from 'axios';

const Test = ()=>{

    const [selectedImage, setSelectedImage] = useState(null)
    const apiKey = "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"
    const [chatHistory, setChatHistory] = useState([])
    const [message, setMessage] = useState({})
    const [content, setContent] = useState([])

    const contentTemplete = (type, content)=>{
        if(type ==="text"){
            return {
                'type': 'text',
                'text': content
            }
        }else{
            return {
                'type': 'image_url',
                'image_url': content
            }
        }
    }

    const onChangeMessage = (event)=>{
        const newContent = contentTemplete("text", event.target.value)
        const msg =  {
            'role': 'user',
            'content': [newContent]
            }
        setMessage(msg)
       
    }
    
    

    const onPressPickPhoto = (event)=>{
        const file = event.target.files[0];
        if (file) {
          const imageUrl = URL.createObjectURL(file);
          console.log(imageUrl)
          setSelectedImage(imageUrl);
        }
    }
    
    const callOpenAi = ()=>{
        console.log("pressed")
      
        const history = [...chatHistory]
        history.push(message)
       
        const url = 'https://api.openai.com/v1/chat/completions';
        const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer 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'
        };

        const data = {
        'model': 'gpt-4-vision-preview',
        'messages': history,
        'max_tokens': 100
        };

    

        axios.post(url, data, { headers })
        .then(response => {
            console.log(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    }



    return(
        <div>
            <div className=' w-144 h-0144'>
                <img src = {selectedImage} />
            </div>
            <div className=' absolute h-20 w-full bg-blue bottom-0 flex flex-row justify-center items-center'>
                <input 
                onChange={(event)=>{
                    onChangeMessage(event)
                }}
                
                className=' m-10 w-144 h-10 px-3'>
                </input>
                 <input 
                 type="file"
                 name="myImage"
                 onChange={(event)=>{
                    onPressPickPhoto(event)
                 }}
                 className='h-10 w-10 bg-black text-green mr-1'>
                        

                </input>
                <button 
                onClick={callOpenAi}
                className='h-10 w-10 bg-black text-green'>
                    send

                </button>
            </div>

        </div>
    )
}

export default Test